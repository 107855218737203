<template>
  <div v-if="products" >
    <nav class="navbar top" role="navigation" aria-label="main navigation">
      <div class="navbar-brand is-justify-content-space-between" style="width: 100%">
        <div class="navbar-item">
          <h1 class="title mb-0">
            Products
          </h1>
        </div>

        <div class="navbar-item">
          <button class="button is-black" @click="showCreateProductModal = true">
            Add Product
          </button>
        </div>
      </div>
    </nav>

    <section class="section pt-0">
      <div class="table-container">
        <table class="table is-fullwidth">
          <thead class="has-background-light">
            <tr>
              <th>Image</th>
              <th>Line 1</th>
              <th>Line 2</th>
              <th>Charge</th>
              <th></th>
            </tr>
          </thead>
          <tbody style="white-space: nowrap;">
            <tr v-for="product in products" :key="product.id">
              <td>
                <figure v-if="product.images[0]" class="image is-5by4">
                  <img :src="product.images[0].url" style="border-radius: .5rem;" />
                </figure>
              </td>
              <td>{{ product.line1 }}</td>
              <td>{{ product.line2 }}</td>
              <td v-html="formatCurrency(product.charge, 'CAD')" />
              <td class="has-text-right">
                <div class="buttons is-justify-content-flex-end">
                  <button class="button is-info" @click="productToEdit = product">
                    Edit
                  </button>
                  <button class="button is-danger" @click="doDeleteProduct(product.id)">
                    Delete
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>

    <AddProductModal
      v-if="showCreateProductModal || productToEdit"
      :productToEdit="productToEdit"
      @close="showCreateProductModal = false, productToEdit = null"
    />
  </div>
</template>

<script>
import { ref } from 'vue'
import getCollection from '@/composables/getCollection'
import useDocument from '@/composables/useDocument'
import AddProductModal from '@/components/Admin/AdminAddProductModal.vue'
import filters from '@/utils/filters'

export default {
  components: { AddProductModal },
  setup() {
    const { documents: products } = getCollection('products')

    const snippet = (string) => {
      return string.length > 25 ? string.substring(0, 20) + '...' : string
    }

    const showCreateProductModal = ref(false)
    const productToEdit = ref('')

    const doDeleteProduct = async (productId) => {
      const { deleteDoc: deleteProduct } = useDocument('products', productId)      
      await deleteProduct()
    }

    return {
      products,
      snippet,

      showCreateProductModal,
      productToEdit,

      doDeleteProduct,

      ...filters
    }
  }
}
</script>

<style scoped>
.table td {
  vertical-align: middle;
}
</style>