<template>
  <Modal
    title="Add Product Image"
    :isSmall="true"
    @close="$emit('close')"
  >
    <div class="columns is-multiline">
      <div class="column is-12">
        <UploadFile
          v-if="!blankProductImage.url"
          target="productImages"
          :deletePath="null"
          :isBoxed="true"
          @progress="isPending = $event"
          @imageUploaded="blankProductImage.url = $event.downloadUrl"
        />
        <figure v-else class="image">
          <img :src="blankProductImage.url" />
        </figure>
      </div>
      <div class="column field is-3">
        <label class="label">Color</label>
        <input
          type="color"
          @change="blankProductImage.color = $event.target.value"
        >
      </div>
      <div class="column field is-8">
        <label class="label">Sex</label>
        <div class="control">
          <div class="buttons">
            <button
              v-for="sex in sexes"
              :key="sex"
              :class="`button is-rounded ${blankProductImage.sex === sex && 'is-dark'}`"
              @click="blankProductImage.sex = sex"
            >
              {{ sex }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <template v-slot:footer>
      <button
        class="button is-black"
        :disabled="!blankProductImage.url || !blankProductImage.sex || !blankProductImage.color"
        @click="$emit('addImage', blankProductImage)"
      >
        Add image
      </button>
      <button class="button" @click="$emit('close')">
        Close
      </button>
    </template>
  </Modal>
</template>

<script>
import { reactive, inject } from 'vue'
import Modal from '@/components/Modals/Modal'
import FormField from '@/components/Forms/FormField'
import UploadFile from '@/components/UploadFile'

export default {
  emits: ['addImage', 'close'],
  components: { Modal, FormField, UploadFile },
  setup() {
    const isPending = inject('isPending')

    const blankProductImage = reactive({
      url: '',
      sex: '',
      color: '#000000'
    })

    const sexes = ['Unisex', 'Male', 'Female']
    
    return {
      isPending,

      blankProductImage,
      sexes
    }
  }
}
</script>