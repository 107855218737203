<template>
  <div :class="`file ${isBoxed && 'is-boxed'}`">
    <label class="file-label">
      <input class="file-input" type="file" accept="image/png, image/gif, image/jpeg" @change="chooseFile">
      <span :class="`file-cta button ${progress && 'is-loading'}`">
        <span class="file-icon">
          <i class="fa-solid fa-arrow-up-from-bracket"></i>
        </span>
        <span class="file-label">
          Choose a file…
        </span>
      </span>
      <span v-if="deletePath && !isDeleted" class="file-name">
        {{ deletePath }}
      </span>
    </label>

    <button v-if="deletePath && !isDeleted" class="button ml-3" @click="deleteImage()">
      <span>Delete</span>
      <span class="icon">
        <i class="fas fa-times"></i>
      </span>
    </button>
  </div>
</template>

<script>
import { watch, ref } from 'vue'
import useStorage from '@/composables/useStorage'

export default {
  props: ['target', 'deletePath', 'isBoxed'],
  emits: ['progress', 'imageUploaded'],
  setup(props, { emit }) {
    const { uploadData, deleteData, progress, error, resultData } = useStorage(props.target)

    emit('progress', progress.value)

    const chooseFile = (e) => {
      const file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0]
      isDeleted.value = false
      uploadData(file)
    }

    watch(() => resultData.value, (resultData) => emit('imageUploaded', resultData))

    const isDeleted = ref(false)

    const deleteImage = async () => {
      await deleteData(props.deletePath)
      isDeleted.value = true
    }

    return {
      chooseFile,
      progress,
      error,
      isDeleted,
      deleteImage
    }
  }
}
</script>

<style scoped>
.file.is-boxed, .file.is-boxed .file-label {
  width: 100%;
}

.file.is-boxed .file-cta {
  max-height: none;
}
</style>