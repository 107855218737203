<template>
  <Modal
    title="Add New Product"
    @close="$emit('close')"
  >
    <div class="columns is-mobile is-multiline">

      <div v-for="photo in product.images" :key="photo.url" class="column is-6 level is-mobile is-justify-content-center is-relative">
        <figure class="image">
          <img :src="photo.url">
        </figure>

        <button
          class="button is-black is-small is-rounded"
          style="position: absolute; top: 0; right: 0;"
          @click="deletePhoto(photo.url)"
        >
          <Icon icon="fa-solid fa-xmark" />
        </button>
      </div>

      <div class="column is-6 is-flex is-align-items-center is-justify-content-center">
        <div class="file is-boxed" @click="showAddPhotoModal = true">
          <label class="file-label">
            <span class="file-cta">
              <span class="file-icon">
                <i class="fas fa-upload"></i>
              </span>
              <span class="file-label">
                Upload image
              </span>
            </span>
          </label>
        </div>
      </div>

      <div class="column is-12"></div>

      <div v-if="product.images.length" class="column is-12 field">
        <label class="label">Logo Placement</label>
        <figure class="image" style="width: 480px; height: 480px;">
          <Vue3DraggableResizable
            :initW="product.logoConstaints.w"
            :initH="product.logoConstaints.h"
            v-model:x="product.logoConstaints.x"
            v-model:y="product.logoConstaints.y"
            v-model:w="product.logoConstaints.w"
            v-model:h="product.logoConstaints.h"
            v-model:active="product.logoConstaints.active"
            :draggable="true"
            :resizable="true"
          >
            <figure class="image">
              <img src="@/assets/logo.svg" >
            </figure>
          </Vue3DraggableResizable>
          <img :src="product.images[0].url" />
        </figure>
      </div>

      <FormField
        class="column is-6"
        label="Line 1"
        v-model="product.line1"
      />

      <FormField
        class="column is-6"
        label="Line 2"
        v-model="product.line2"
      />

      <FormField
        class="column is-6"
        label="Charge"
        type="money"
        v-model="product.charge"
      />

      <FormField
        class="column is-6"
        label="SKU"
        v-model="product.SKU"
      />

      <div class="column is-12 field">
        <label class="label">Sizes</label>
        <div class="control">
          <div class="buttons">
            <button
              v-for="size in sizes"
              :key="size"
              :class="`button is-rounded ${addedSize(size) && 'is-dark'}`"
              @click="toggleSize(size)"
            >
              {{ size }}
            </button>
          </div>
        </div>
      </div>

      <div class="column is-12 field">
        <label class="label">Shoe sizes</label>
        <div class="control mt-5">
          <div class="buttons">
            <button
              v-for="size in shoeSizes"
              :key="size"
              :class="`button is-rounded ${addedSize(size) && 'is-dark'}`"
              @click="toggleSize(size)"
            >
              {{ size }}
            </button>
          </div>
        </div>
      </div>

      <div class="column is-6 field">
        <label class="label">Restrictions</label>
        <div class="control">
          <input class="input" type="text" v-model="product.restrictions">
        </div>
      </div>

      <div class="column is-12 field">
        <label class="label">Description</label>
        <div class="control">
          <textarea class="textarea" rows="5" maxlength="800" v-model="product.description"></textarea>
          <small>{{ product.description.length }} / 800</small>
        </div>
      </div>
    </div>

    <template v-slot:footer>
      <button
        v-if="productToEdit"
        :class="`button is-black ${isPending && 'is-loading'}`"
        @click="doUpdateProduct()"
      >
        Update Product
      </button>
      <button
        v-else
        :class="`button is-black ${isPending && 'is-loading'}`"
        :disabled="!product.line1 || !product.line2 || !product.description || !product.SKU || !product.charge || !product.images.length"
        @click="doAddProduct()"
      >
        Add Product
      </button>
      <button class="button" @click="$emit('close')">
        Cancel
      </button>
    </template>
  </Modal>

  <AdminUploadImage
    v-if="showAddPhotoModal"
    @addImage="handleAddImage"
    @close="showAddPhotoModal = false"
  />
</template>

<script>
import { reactive, ref, inject } from 'vue'
import useCollection from '@/composables/useCollection'
import useDocument from '@/composables/useDocument'
import Modal from '@/components/Modals/Modal.vue'
import FormField from '@/components/Forms/FormField.vue'
import Icon from '@/components/Icon.vue'
import AdminUploadImage from '@/components/Admin/AdminUploadImage.vue'
import Vue3DraggableResizable from 'vue3-draggable-resizable'
import 'vue3-draggable-resizable/dist/Vue3DraggableResizable.css'
import { timestamp } from '@/firebase/config'

export default {
  props: ['productToEdit'],
  emits: ['close'],
  components: { Modal, FormField, Icon, AdminUploadImage, Vue3DraggableResizable },
  setup(props, { emit }) {
  
    const blankProduct = {
      quantity: 0,
      line1: '',
      line2: '',
      SKU: '',
      sizes: [],
      categories: [],
      chosenColors: [],
      charge: 0,
      description: '',
      restrictions: '',
      images: [],
      logoConstaints: {
        x: 100,
        y: 100,
        w: 100,
        h: 100,
        active: false
      }
    }

    const product = props.productToEdit ? props.productToEdit : reactive({ ...blankProduct })

    const showAddPhotoModal = ref(false)

    const handleAddImage = (image) => {
      console.log('handleAddImage', image)
      product.images.push(image)
      showAddPhotoModal.value = false
    }

    /* Sizes */
    const sizes = ['One Size', 'XS', 'S', 'M', 'L', 'XL']
    const shoeSizes = ['6.5', '7', '7.5', '8', '8.5', '9', '9.5', '10', '10.5', '11', '11.5', '12', '12.5', '13']

    const addedSize = (size) => {
      return product.sizes.find(_size => _size === size)
    }

    const toggleSize = (size) => {
      if (size === 'One Size') {
        product.sizes = addedSize('One Size') ? [] : ['One Size']
        return
      } else if (addedSize('One Size')) return

      if (!addedSize(size)) {
        product.sizes.push(size)
      } else {
        const index = product.sizes.indexOf(size)
        if (index > -1) {
          product.sizes.splice(index, 1)
        }
      }
    }

    const isPending = inject('isPending')

    const doAddProduct = async () => {
      const { addDoc: addProduct } = useCollection('products')
      isPending.value = true
      await addProduct({
        ...product,
        created: timestamp()
      })
      isPending.value = false
      emit('close')
    }

    const doUpdateProduct = async () => {
      const { updateDoc: updateProduct } = useDocument('products', props.productToEdit.id)
      isPending.value = true
      await updateProduct({
        ...product,
        lastUpdated: timestamp()
      })
      isPending.value = false
      emit('close')
    }

    const deletePhoto = async (url) => {
      const { updateDoc: updateProduct } = useDocument('products', props.productToEdit.id)
      isPending.value = true
      const filtered = product.images.filter(image => image.url !== url)      
      await updateProduct({
        images: filtered,
        lastUpdated: timestamp()
      })
      isPending.value = false
      emit('close')
    }
    
    return {
      product,

      showAddPhotoModal,
      handleAddImage,

      sizes,
      shoeSizes,
      addedSize,
      toggleSize,

      isPending,
      doAddProduct,
      doUpdateProduct,

      deletePhoto,
    }
  }
}
</script>